import {h} from 'preact'
import { useState } from 'preact/hooks';
import regioni from './regioni'
import tipologie from './tipologie'
import "./style.css";

export default function App(props) {
  const [regione, setRegione] = useState('');
  const [comune, setComune] = useState(null);
  const [comuni, setComuni] = useState([]);
  const [provincia, setProvincia] = useState(null);
  const [province, setProvince] = useState([]);
  const [tipologia, setTipologia] = useState(null);
  let url = '';

  switch (props.partner) {
      case 'QDS':
          url = 'qds.asteannunci.it'
          break
      case 'GDS':
          url = 'gds.asteannunci.it'
          break
      case 'CZINFO':
          url = 'czinfo.asteannunci.it'
          break
      default:
          throw new Error('Partner non supportato')
  }

  const encodeQueryData = (data) => {
    const ret = [];
    for (let d in data)
      if(data[d] && data[d].length)
        ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
    return ret.join('&');
 }
  const onSetRegion = (e) => {
    let value = e.target.value
    setRegione(value)
    if(!value.length) {
      return;
    }
    fetch(`https://${url}/api/aste/select?${encodeQueryData({regione: value})}`)
    .then(res => res.json())
    .then(data => setProvince(data))
    .catch(e => {})
  }
  const onSetProvincia = (e) => {
    let value = e.target.value
    setProvincia(value)
    if(!value.length) {
      return;
    }
    fetch(`https://${url}/api/aste/select?${encodeQueryData({provincia: value})}`)
    .then(res => res.json())
    .then(data => setComuni(data))
    .catch(e => {})
  }
  const onSubmit = (e) => {
    e.preventDefault()
    window.location.href = `${props.action}?partner=${props.partner}&${encodeQueryData({regione,comune,provincia,tipologia})}`
  }
  return (
    <div className="ed-widget well" style={{width: props.width, textAlign: 'center'}}>
      <form onSubmit={onSubmit}>
        <label htmlFor="regione">Ricerca Vendite Giudiziarie</label>
        <select id="regione" className="form-control" value={regione} onChange={onSetRegion}>
          <option value="">Seleziona regione</option>
          {regioni.map(({value, text}) => <option value={value}>{text}</option>)}
        </select>
        <select className="form-control" value={provincia} onChange={onSetProvincia}>
          <option value="">Seleziona provincia</option>
          {province.map(({provincia}) => <option value={provincia}>{provincia}</option>)}
        </select>
        <select className="form-control" value={comune} onChange={(e) => setComune(e.target.value)}>
          <option value="">Seleziona comune</option>
          {comuni.map(({citta}) => <option value={citta}>{citta}</option>)}
        </select>

        <select className="form-control" value={tipologia} onChange={(e) => setTipologia(e.target.value)}>
          <option value="">Seleziona tipologia</option>
          {tipologie.map(({raggruppamento, descrRaggruppamento}) => <option value={raggruppamento}>{descrRaggruppamento}</option>)}
        </select>
        <button className="btn btn-primary" type="submit">Mostra il risultato</button>
      </form>
    </div>
  );
}
