export default [
  {
    value: 'Abruzzo',
    text: 'Abruzzo'
  },
  {
    value: 'Basilicata',
    text: 'Basilicata'
  },
  {
    value: 'Calabria',
    text: 'Calabria'
  },
  {
    value: 'Campania',
    text: 'Campania'
  },
  {
    value: 'Emilia Romagna',
    text: 'Emilia Romagna'
  },
  {
    value: 'Friuli Venezia Giulia',
    text: 'Friuli Venezia Giulia'
  },
  {
    value: 'Lazio',
    text: 'Lazio'
  },
  {
    value: 'Liguria',
    text: 'Liguria'
  },
  {
    value: 'Lombardia',
    text: 'Lombardia'
  },
  {
    value: 'Marche',
    text: 'Marche'
  },
  {
    value: 'Molise',
    text: 'Molise'
  },
  {
    value: 'Piemonte',
    text: 'Piemonte'
  },
  {
    value: 'Puglia',
    text: 'Puglia'
  },
  {
    value: 'Sardegna',
    text: 'Sardegna'
  },
  {
    value: 'Sicilia',
    text: 'Sicilia'
  },
  {
    value: 'Toscana',
    text: 'Toscana'
  },
  {
    value: 'Trentino Alto Adige',
    text: 'Trentino Alto Adige'
  },
  {
    value: 'Umbria',
    text: 'Umbria'
  },
  {
    value: 'Valle d\'Aosta',
    text: "Valle d'Aosta"
  },
  {
    value: 'Veneto',
    text: 'Veneto'
  }
]
